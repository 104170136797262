jQuery(document).ready(function ($) {
  // homepage rotator
  if (!$('body').hasClass('dnnEditState')) {
    $('.banners').cycle({
      speed: 600,
      manualSpeed: 600,
      timeout: 6500,
      // timeout: 3000,
      fx: 'fade',
      manualFx: 'fade',
      pager: '.banner-pager',
      pagerTemplate: '<span tab-index="0"></span>',
      slides: '.banner',
      swipe: true,
      autoHeight: false
    });
  }
  // sentinel label
  // $('.cycle-sentinel')
  // section titles
  $('div[role="region"]:not(.wrap-banners) h2:first-of-type').each(function () {
    // $(this).children('h2').first().text();
    // console.log($(this).text());
    $(this).closest('div[role="region"]').attr('aria-label', $(this).text().trim());
  });
});